import { Link } from "gatsby"
import React from "react"
import { Header, Icon, Menu, Responsive } from "semantic-ui-react"
import Image from './image'

const CompHeader = ({ siteTitle }) => (
  <header>
    <div class="image-container">
      <Image />
    </div>

    <Header as="h1">
      Thomas Waldecker
      <Header.Subheader>Software Developer</Header.Subheader>
    </Header>

    <Menu vertical text>
      <Menu.Item as={Link} to="/">
        Home
      </Menu.Item>
      <Menu.Item as={Link} to="/about">
        About
      </Menu.Item>
      <Menu.Item as={Link} to="/portfolio">
        Portfolio
      </Menu.Item>
    </Menu>

    <Responsive minWidth={601}>
      <a href="https://github.com/twaldecker"><Icon name="github" size="large" /></a>
      <a href="https://www.linkedin.com/in/thomas-waldecker-745397132/"><Icon name="linkedin" size="large"/></a>
      <a href="https://www.xing.com/profile/Thomas_Waldecker/cv"><Icon name="xing" size="large"/></a>
      <a href="https://stackoverflow.com/users/605586/thomas"><Icon name="stack overflow" size="large"/></a>
      <a href="mailto:thomas.waldecker@gmail.com"><Icon name="mail square" size="large"/></a>
    </Responsive>

  </header>
)

export default CompHeader
