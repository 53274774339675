/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Icon, Segment } from "semantic-ui-react"

import 'semantic-ui-less/semantic.less'
import "./layout.css"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Container style={{paddingTop: "1em", paddingBottom: "2em", minHeight: "calc(100vh - 68px)"}}>
        <div className="menu-content">
          <Header siteTitle={data.site.siteMetadata.title} />
          <main>{children}</main>
        </div>
      </Container>
      
      <footer>
        <Segment basic inverted visible="true" direction="bottom" stlye={{height: "60px"}}>
          <Container textAlign="center">
            Made with <Icon name="like" color="red" /> in Starnberg <br />
            © {new Date().getFullYear()} Thomas Waldecker | <Link to="/impressum">Impressum</Link>
          </Container>
        </Segment>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
